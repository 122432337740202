import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { BASE_ROUTE_NAME } from '../../../config/index';
import { scrollTopSmooth } from "../../../utils/scroll";
import codeChart from "../../images/codeChart.png";
import serverCode from "../../images/serverCode.png";
import contract from "../../../images/contract.png";

const SiderMenu: FC<any> = ({ setAlterFlag }) => {
  const history = useHistory();
  const [floatingActive, setFloatingActive] = useState(-1);

  const floatingList = [
    {
      icon: 'icon-headset',
      text: "客服",
    },
    {
      icon: 'icon-wecode',
      text: "公众号",
    },
    {
      icon: 'icon-handshake',
      text: '加入我们',
    },
  ];

  const floatingWindow = (ind: any) => {
    if (ind !== floatingList.length - 1) {
      setFloatingActive(ind);
    } else {
      scrollTopSmooth();
      setFloatingActive(-1);
    }
    if (ind === 2) {
      history.push(`${BASE_ROUTE_NAME}/about/join`);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <div className="floating-window">
        <div className="operators">
          {floatingList.map((item, index) => {
            return (
              <div
                onClick={() => floatingWindow(index)}
                onMouseEnter={() => index !== 3 && setFloatingActive(index)}
                onMouseLeave={() => index === 2 && setFloatingActive(-1)}
                key={index}
                className={`operator ${floatingActive === index ? "floating-active" : ""}`}
              >
                <div className="iconfont-box">
                  <span className={`iconfont ${item.icon}`}></span>
                </div>
                <span className="text">{item.text}</span>
              </div>
            );
          })}
        </div>
        <div
          onClick={() => scrollTopSmooth()}
          className="go-top"
        >
          <span className={`iconfont icon-arrow-up`}></span>
        </div>
      </div>

      {/* 关注公众号二维码 */}
      <dl
        className="code-chart"
        style={{ display: floatingActive === 1 ? "block" : "none" }}
        onMouseLeave={() => setFloatingActive(-1)}
      >
        <dt>- 官方公众号 -</dt>
        <dd style={{ backgroundImage: `url(${codeChart})` }}></dd>
      </dl>
      {/* 售前浮窗 */}
      <dl
        style={{ display: floatingActive === 0 ? "block" : "none" }}
        onMouseLeave={() => setFloatingActive(-1)}
        className="pre-sale"
      >
        <dt>- 官方客服 -</dt>
        <dd>
          <img src={serverCode} alt="" />
          <p className="mobile">
            <img src={contract} alt="" className="contract-icon" />
            010-87531591
          </p>
          <div onClick={() => setAlterFlag(true)} className='btn'>申请试用</div>
        </dd>
      </dl>
    </>
  );
};

export default SiderMenu;
