// 导入所需组件
import HomePage from "../pages/homePage";
import ProductIntroduction from "../pages/productIntroduction";
import Solution from "../pages/solution";
import Resource from "../pages/resource";
import ResourceCenter from "../pages/resourceCenter";
import AboutUs from "../pages/aboutUs";
import JoinUs from "../pages/joinUs";
import NewsDetail from "../pages/newsDetail";
import Contact from "../pages/contact";
import FastConsumption from "../pages/solution/fastConsumption";
import OfficialContact from "../pages/officialContact";
import GroundPage from "../pages/groundPage";
import { BASE_ROUTE_NAME } from "../config/index";
import Mxx from "../pages/solution/mxx";

interface IRoutes {
  path: string;
  exact: boolean;
  name: string;
  component?: any;
  children?: Array<IRoutes>;
  redirect?: string;
  parentPath?: string;
  hidden?: boolean;
}
const Routes: IRoutes[] = [
  {
    path: `${BASE_ROUTE_NAME === "" ? "/" : BASE_ROUTE_NAME}`,
    exact: true,
    name: "首页",
    component: HomePage,
  },
  {
    path: `${BASE_ROUTE_NAME}/product`,
    exact: true,
    name: "产品介绍",
    component: ProductIntroduction,
  },
  {
    path: `${BASE_ROUTE_NAME}/solution`,
    exact: true,
    name: "解决方案",
    component: Solution,
    redirect: `${BASE_ROUTE_NAME}/solution/fb`,
  },
  // {
  //   path: `${BASE_ROUTE_NAME}/solution/ft`,
  //   exact: true,
  //   name: "时尚潮流行业",
  //   component: MakeUp,
  //   parentPath: `${BASE_ROUTE_NAME}/solution`,
  // },
  {
    path: `${BASE_ROUTE_NAME}/solution/fb`,
    exact: true,
    name: "食品饮料行业",
    component: Solution,
    parentPath: `${BASE_ROUTE_NAME}/solution`,
  },
  {
    path: `${BASE_ROUTE_NAME}/solution/fmcg`,
    exact: true,
    name: "快消零售行业",
    component: FastConsumption,
    parentPath: `${BASE_ROUTE_NAME}/solution`,
  },
  {
    path: `${BASE_ROUTE_NAME}/solution/mxx`,
    exact: true,
    name: "时尚潮流行业",
    component: Mxx,
    parentPath: `${BASE_ROUTE_NAME}/solution`,
  },
  {
    path: `${BASE_ROUTE_NAME}/Resource`,
    exact: true,
    name: "",
    hidden: true,
    component: Resource,
  },
  {
    path: `${BASE_ROUTE_NAME}/news`,
    exact: true,
    name: "新闻中心",
    component: ResourceCenter,
  },
  {
    path: `${BASE_ROUTE_NAME}/news/detail/:id`,
    exact: true,
    name: "",
    component: NewsDetail,
    hidden: true,
    parentPath: `${BASE_ROUTE_NAME}/news`,
  },
  {
    path: `${BASE_ROUTE_NAME}/about`,
    exact: true,
    name: "关于我们",
    component: AboutUs,
    redirect: `${BASE_ROUTE_NAME}/about/introduce`,
  },
  {
    path: `${BASE_ROUTE_NAME}/about/introduce`,
    exact: true,
    name: "公司介绍",
    component: AboutUs,
    parentPath: `${BASE_ROUTE_NAME}/about`,
  },
  {
    path: `${BASE_ROUTE_NAME}/about/join`,
    exact: true,
    name: "加入我们",
    component: JoinUs,
    parentPath: `${BASE_ROUTE_NAME}/about`,
  },
  {
    path: `${BASE_ROUTE_NAME}/contact`,
    exact: true,
    name: "",
    component: Contact,
    hidden: true,
  },
  {
    path: `${BASE_ROUTE_NAME}/officialContact`,
    exact: true,
    name: "",
    component: OfficialContact,
    hidden: true,
  },
  {
    path: `${BASE_ROUTE_NAME}/groundPage`,
    exact: true,
    name: "",
    component: GroundPage,
    hidden: true,
  },
];
export default Routes;
