import { FC, memo, useState, useRef, useContext, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import MenuMobile from "../menuMobile";
import Routes from "../../../router";
import { AppContext } from '../../../context';
import { scrollTop } from '../../../utils/scroll';
import { BASE_ROUTE_NAME } from '../../../config/index';
import { IRoutes } from '../../interface';
import logoWhite from "../../../images/logo-white.svg";
import logoBlack from "../../../images/logo-black.svg";
import mediaNavIcon from "../../images/media-nav-icon.png";

const Header: FC<{
  setAlterFlag: (flag: boolean) => void;
}> = memo(({
  setAlterFlag
}) => {
  const { navStyle } = useContext(AppContext);
  const [showMenuMobile, setShowMenuMobile] = useState<boolean>(false);
  const [parentPath, setParentPath] = useState('');
  const [showSubMenu, setShowSubMenu] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  const routeMap = useRef<Record<string, IRoutes>>({});
  const routeModalData = useRef<Record<string, IRoutes[]>>(
    Routes?.reduce<Record<string, IRoutes[]>>((res, cur) => {
      routeMap.current[cur.path] = cur;
      if (cur.parentPath && !cur.hidden) (res[cur.parentPath] ||= []).push(cur);
      return res;
    }, {})
  );

  useEffect(() => {
    if (routeMap.current?.[pathname]?.parentPath) return setParentPath(routeMap.current?.[pathname]?.parentPath as string);
    if (pathname.indexOf(`/news/detail`) > -1) return setParentPath(`${BASE_ROUTE_NAME}/news`);
    return setParentPath('');
  }, [pathname])

  return (
    <>
      <header className={`header-wrapper ${navStyle}`}>
        <div className="head-content">
          <div className="left">
            <img className="logo" src={navStyle === 'white' ? logoWhite : logoBlack} alt="" />
            <img
              className="menu-btn"
              src={mediaNavIcon}
              onClick={() => setShowMenuMobile(true)}
              alt=""
            />
            <ul className="navs">
              {Routes?.map((item) => {
                if (item.parentPath || item.hidden) return null;
                return (
                  <li
                    key={item.path}
                    onMouseEnter={() => setShowSubMenu(true)}
                    onMouseLeave={() => setShowSubMenu(false)}
                    className={
                      (routeModalData.current?.[item.path]?.length &&
                        routeModalData.current[item.path].find(
                          (ele) => ele.path === pathname
                        )) ||
                          (item.redirect || item.path) === pathname ||
                            parentPath === item.path
                              ? "navActive"
                              : ""
                    }
                  >
                    <Link to={item.redirect || item.path}>{item.name}</Link>
                    {showSubMenu && routeModalData.current?.[item.path]?.length > 0 && (
                      <ul className="nav-children">
                        {routeModalData.current?.[item.path]?.map((item) => {
                          return (
                            <li key={item.path} onClick={(e) => {
                              e.stopPropagation();
                              scrollTop();
                              setShowSubMenu(false);
                            }}>
                              <Link to={item.path}>{item.name}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <p className="contact-us" onClick={() => setAlterFlag(true)}>
            <span>申请试用</span>
          </p>
        </div>
      </header>

      {/* 移动端路由 */}
      {showMenuMobile && (
        <MenuMobile
          showMenuMobile={showMenuMobile}
          setShowMenuMobile={setShowMenuMobile}
        />
      )}
    </>
  )
})

export default Header;