import { FC, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../../../router';
import logo from '../../../images/logo-white.svg';
import './media.less';

const MenuMobile: FC<any> = ({ setShowMenuMobile }) => {
    const history = useHistory();

    return <div className='menu-mobile-fixed'>
        <div className='header'>
            <img className='logo' src={logo} alt='' />
            <span className='iconfont icon-close' onClick={() => setShowMenuMobile(false)}></span>
        </div>
        {
            routes.map((route: any) => {
                return <Fragment key={route.path}>
                    {
                        route.name && <div onClick={() => {
                            history.push(route.path);
                            window.scrollTo({ top: 0 });
                            setShowMenuMobile(false);
                        }} className={`route ${route.parentPath ? 'route-theme' : ''}`}>
                            {route.name}
                        </div>
                    }
                </Fragment>
            })
        }
    </div>
}

export default MenuMobile;