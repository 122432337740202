import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { Toaster } from 'react-hot-toast';
import Routes from "../router";
import { BASE_ROUTE_NAME } from '../config/index';
import Header from "./components/header";
import Footer from "./components/footer";
import AlterWindow from "./components/alterWindow";
import MenuMobile from "./components/menuMobile";
import SiderMenu from "./components/siderMenu";
import SiderMenuMobile from "./components/siderMenuMobile";
import ContactUsMobile from './components/contactMobile';
import useViewport from "../hooks/useViewport";
import useNavStyle from "../hooks/useNavStyle";
import "./index.less";
import "./media.less";

const Layout = () => {
  const { isMobile } = useViewport();
  const [showContactUsMobile, setShowContactUsMobile] = useState(false);
  const [showMenuMobile, setShowMenuMobile] = useState<boolean>(false);
  const [alterFlag, setAlterFlag] = useState(false);
  // const [hiddenFooter, setHiddenFooter] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  // useEffect(() => {
  //   if (
  //     [
  //       `${BASE_ROUTE_NAME}/news`,
  //     ].includes(pathname)
  //   ) setHiddenFooter(true)
  //   if (pathname.indexOf(`${BASE_ROUTE_NAME}/detail`) > -1) setHiddenFooter(true)
  // }, [pathname])

  useNavStyle();

  if (
    [
      `${BASE_ROUTE_NAME}/contact`,
      `${BASE_ROUTE_NAME}/officialContact`,
      `${BASE_ROUTE_NAME}/groundPage`
    ].includes(pathname)
  ) return <>{renderRoutes(Routes)}</>

  return (
    <div className={`${isMobile ? "layout-app-mobile" : "layout-app"}`}>
      <Header setAlterFlag={setAlterFlag} />
      <main>{renderRoutes(Routes)}</main>
      <Footer setShowContactUsMobile={setShowContactUsMobile} />
      {/* {!hiddenFooter && } */}

      {/* 右下角操作栏 */}
      {isMobile ? (
        <SiderMenuMobile />
      ) : (
        <SiderMenu setAlterFlag={setAlterFlag} />
      )}

      {showContactUsMobile && (
        <ContactUsMobile setShowContact={() => setShowContactUsMobile(false)} />
      )}

      {/* 申请试用弹窗 pc  */}
      {alterFlag ? <AlterWindow setAlterFlag={setAlterFlag} /> : null}

      {/* 移动端路由 */}
      {showMenuMobile && (
        <MenuMobile
          showMenuMobile={showMenuMobile}
          setShowMenuMobile={setShowMenuMobile}
        />
      )}
      <Toaster />
    </div>
  );
};
export default Layout;
