import Clipboard from 'clipboard';
import toast from 'react-hot-toast';

function clipboardSuccess (text = '复制成功') {
  toast.success(text)
}

function clipboardError (text = '复制失败') {
  toast.error(text)
}

export default function handleClipboard (text, event, options = { showToast: true }) {
  options = Object.assign({}, { showToast: true, successText: '复制成功', errorText: '复制失败' }, options)
  const clipboard = new Clipboard(event.target, {
    text: () => text
  })

  clipboard.on('success', () => {
    if (options.showToast) {
      clipboardSuccess(options.successText)
    }
    clipboard.off('error')
    clipboard.off('success')
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    if (options.showToast) {
      clipboardError(options.errorText)
    }
    clipboard.off('error')
    clipboard.off('success')
    clipboard.destroy()
  })
  clipboard.onClick(event)
}
